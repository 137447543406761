import styled, { css } from "styled-components";
import { Container as Wrapper } from "../../styles/UI";

import checkIcon from "../../images/check_circle.svg";
import closeIcon from "../../images/close-circle-line.svg";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: visible;

  .only-mobile {
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: none;
    }
  }
`;

export const Container = styled(Wrapper)`
  .cta {
    position: relative;
    z-index: 10;

    h2 {
      font-weight: normal;
      font-size: 25px;
      line-height: 42px;
      letter-spacing: -0.01em;
      margin-top: 16px;
    }

    h1 {
      font-size: 35px;
      font-weight: 600;
      letter-spacing: -0.01em;
      margin-bottom: 24px;
      line-height: 1.25;
    }

    p {
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .illustration {
    z-index: 1;
    margin-top: 0px;
    position: relative;

    .bg-logo {
      position: absolute;
      z-index: 0;
      height: 800px;
      right: -180px;
      top: -240px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        left: 80px;
        top: -240px;
        height: 1360px;
      }
    }
  }

  .taxdashs {
    z-index: 10;
    flex-grow: 1;
    pointer-events: none;
    position: relative;
    transition: all 300ms ease-in-out;
    /* filter: drop-shadow(0px 16px 64px rgba(0, 0, 0, 0.5))
      drop-shadow(0px 16px 128px rgba(0, 0, 0, 0.5)); */

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      img {
        width: 100%;
        margin-top: 40px;
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      ${(p) =>
        p.$fluid &&
        css`
          height: 160%;
          margin-left: -5%;
          margin-right: -20%;
          margin-top: -27%;
          margin-bottom: -35%;
        `}
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    max-height: 820px;
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-columns: minmax(460px, 1fr) 1fr;
    grid-column-gap: 32px;
    align-items: center;
  }
`;

export const Comprar = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(37, 37, 74, 0.25);
  border-radius: 0px 16px;
  width: 100%;
  padding: 0;

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: ${(p) => (p.finalizado ? "block" : "grid")};
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    width: ${(p) => (p.finalizado ? "620px" : "100%")};
    margin: 0px auto;
  }

  .form {
    padding: 16px 20px;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      padding: 24px 32px;
    }
  }

  .hide {
    display: none;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: block;
    }
  }

  .titulo {
    display: flex;
    align-items: center;

    h2 {
      font-size: 24px;
      color: ${(p) => p.theme.primary};
      font-weight: 600;
      margin-left: 12px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        font-size: 32px;
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      img {
        height: 40px;
      }
    }
  }

  .only-mobile {
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: none;
    }
  }

  p.only-desktop {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(p) => p.theme.text};
    margin-top: 16px;

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      display: none;
    }
  }

  p.descricao {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${(p) => p.theme.text};
    margin-top: 16px;
  }

  p.obs {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${(p) => p.theme.text};
    margin-top: 8px;
  }

  p.gray {
    color: ${(p) => p.theme.textGray};
  }

  p.outras-formas {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${(p) => p.theme.textGray};
    text-align: right;
  }

  p.error {
    font-size: 14px;
    color: ${(p) => p.theme.danger};
    margin-top: 16px;
    font-weight: 600;
    text-align: right;
  }
`;

export const SelectTipo = styled.div`
  margin-top: 20px;

  p.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #909090;
  }

  .tipos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .tipo {
    padding: 24px 16px;
    border-radius: 0px 8px;
    position: relative;
    background: #f6f6f6;
    border: 2px solid #f6f6f6;
    cursor: pointer;

    .recomendado {
      position: absolute;
      border-radius: 2px;
      padding: 2px 4px;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      top: 0;
      left: 8px;
      background: ${(p) => p.theme.primary};
      transform: translateY(-50%);
    }

    .radio {
      position: absolute;
      top: 8px;
      right: 12px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background: ${(p) => p.theme.branco};
      padding: 3px;

      .radio-select {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: none;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: ${(p) => p.theme.text};
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #6b6b6c;
    }
  }

  .active {
    background: #f4f3ff;
    border: 2px solid #00458e;

    .radio {
      .radio-select {
        background: ${(p) => p.theme.primary};
      }
    }
  }
`;

export const ValoresCompra = styled.div`
  margin-top: 32px;
  color: ${(p) => p.theme.text};

  .hide {
    display: none;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: block;
    }
  }

  p.error {
    font-size: 14px;
    color: ${(p) => p.theme.danger};
    margin-top: -12px;
    margin-bottom: 16px;
    font-weight: 600;
    text-align: right;
  }

  .parcelamento {
    margin-bottom: 16px;

    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      span.aditional {
        color: ${(p) => p.theme.textGray};
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      span.aditional {
        color: ${(p) => p.theme.textGray};
      }
    }

    &.small {
      p {
        font-size: 16px;
      }
    }
  }
`;

export const Endereco = styled.div`
  .row-endereco {
    display: flex;
    gap: 24px;

    div:first-child {
      flex-grow: 1;
    }
  }
`;

export const RevisarPedido = styled.div`
  margin: 24px 0 8px;

  button.editar {
    background: none;
    color: ${(p) => p.theme.textGray};
    font-size: 16px;
    padding: 4px 8px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover {
      background: ${(p) => p.theme.lightGray};
    }
  }

  .row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: ${(p) => p.theme.text};
    }
  }
`;

export const Finalizacao = styled.div`
  text-align: center;
  color: ${(p) => p.theme.text};
  padding: 24px;

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 32px 40px;
    grid-column: 1 / span 2;
  }

  img {
    margin: 40px 0 40px;
  }

  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    text-align: left;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    margin-top: 24px;
  }
`;

export const Features = styled.div`
  color: ${(p) => p.theme.text};
  border-left: 1px solid #dfe0e2;
  padding: 24px 32px;

  @media (max-width: ${(p) => p.theme.bp.tablet}) {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 32px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.01em;
      margin-bottom: 32px;

      span {
        font-weight: 400;
      }
    }
  }

  .clientes {
    margin-top: 64px;

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: ${(p) => p.theme.text};
    }
  }

  .features {
    ul {
      list-style-type: none;
      margin: 0;

      li {
        padding-left: 36px;
        padding-bottom: 20px;
        background-image: url(${checkIcon});
        background-position: 0 0;
        background-repeat: no-repeat;
        margin-left: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: ${(p) => p.theme.text};
        text-align: left;
      }
    }
    .obs {
      padding: 16px 8px;

      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: ${(p) => p.theme.textGray};
        text-align: left;
        margin-bottom: 8px;
      }
    }
  }

  .indicado {
    padding: 36px 24px;
    border-top: 1px solid #e3e3e3;
    color: ${(p) => p.theme.textGray};
    text-align: left;

    p {
      color: ${(p) => p.theme.textGray};
      font-style: normal;
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  ul.close li {
    background-image: url(${closeIcon});
    color: ${(p) => p.theme.textDarkGray};
  }
`;

export const Faq = styled.section`
  padding: 64px 0;
  background: ${(p) => p.theme.bgGray};

  h5.title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 32px;
  }

  p.duvidas {
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-top: 40px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 100px 0;

    p.duvidas {
      margin-top: 86px;

      div {
        margin-left: 16px;
      }
    }
  }
`;
